import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { DISCOVER_KEY } from '@/config/routeKeys';
import { getQueryStringParams } from '@/helpers/routing';

const useRedirectBackToHref = ({ shouldRedirect = false } = {}) => {
  const { push: routerPush } = useRouter();

  // Redirect back to the page the user came from
  useEffect(() => {
    if (shouldRedirect) {
      const queryStringParams = getQueryStringParams();

      if (queryStringParams.href) {
        routerPush(queryStringParams.href);
      } else {
        routerPush(DISCOVER_KEY);
      }
    }
  }, [shouldRedirect, routerPush]);
};

export default useRedirectBackToHref;