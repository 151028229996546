import { useCallback, useState } from 'react';
import { SignInPageProvider } from './common/contexts/page';
import SignInModal from '@/components/app/SignInModal';
import Meta from '@/components/app/Meta';
import Page from '@/components/app/Page';
import useUser from '@/hooks/api/useUser';
import useRedirectBackToHref from '@/hooks/routing/useRedirectBackToHref';
import { standardisedGetServerSideProps } from '@/helpers/ssr';

const SignInPage = () => {
  const { isLoggedInWithProvider } = useUser();
  const [hasSkipped, setHasSkipped] = useState(false);

  useRedirectBackToHref({ shouldRedirect: isLoggedInWithProvider || hasSkipped });

  const handleClickSkipLogin = useCallback(() => setHasSkipped(true), []);

  return (
    <Page
      hideHeader
      meta={ (
        <Meta title='Sign in' />
      ) }
      pageId='sign-in'
    >
      <SignInPageProvider>
        <SignInModal onClickSkip={ handleClickSkipLogin } open />
      </SignInPageProvider>
    </Page>
  );
};

export const getServerSideProps = standardisedGetServerSideProps();

export default SignInPage;